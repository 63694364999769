export default {
    namespaced: true,

    state: {
        apiWebToken: "",
        apiWebTokenExpiration: 0,
        accessString: "",
        posID: 0,
        cashierID: 1,
        hybridEnabled: false,
        hybridAddress: null,
    },

    mutations: {
        setHybridEnabled(state, data) {
            state.hybridEnabled = data;
        },
        setHybridAddress(state, data) {
            state.hybridAddress = data;
        },
        setResetPasswordCreds(state, data) {
            state.userId = data.userId;
            state.resetToken = data.resetToken;
        },
        deleteResetPasswordCreds(state, data) {
            if (state.hasOwnProperty('userId') && state.hasOwnProperty('resetToken')) {
                delete state.userId;
                delete state.resetToken;
            }
        },
        update(state, data) {
            state = data;
        },
        init(state, data) {
            state.accessString = data.accessString;
            state.posID = data.posID;
            state.cashierID = parseInt(data.cashierID);
        },
        updateToken(state, token) {
            state.apiWebToken = token;
        },
        clear(state) {
            state.apiWebToken = "";
            state.apiWebTokenExpiration = 0;
            state.accessString = "";
            state.posID = 0;
            state.cashierID = 1;
            state.hybridEnabled = false;
            state.hybridAddress = null;
        },
        setCashierID(state, cashierID) {
            state.cashierID = parseInt(cashierID);
        },
        updateTokenExpiration(state, data) {
            state.apiWebTokenExpiration = data;
        },
        restoreAfterVuexReset(state, data) {
            state.apiWebToken = data.apiWebToken;
            state.apiWebTokenExpiration = data.apiWebTokenExpiration;
            state.accessString = data.accessString;
            state.posID = data.posID;
            state.cashierID = data.cashierID;
        }
    },

    actions: {
        update({commit}, data) {
            commit('update', data);
        },
        init({commit}, data) {
            commit('init', data);
        },
        updateToken({commit}, token) {
            commit('updateToken', token);
        },
        clear({commit}) {
            commit('clear');
        },
        setCashierID({commit}, data) {
            commit('setCashierID', data);
        },
        updateTokenExpiration({commit}, data) {
            commit('updateTokenExpiration', data);
        }
    },

    getters: {
        imageUrl : (state) => {
            
            return (image, dimensions=null, filters=null)=> {
                if(!image)
                    return null;
                const baseUrl = `https://img.3pos.de/unsafe${dimensions ? "/"+dimensions : ""}${filters?"/filters:"+filters:""}/billsys_${state.posID}/original/`
                return `${baseUrl}${image}`
            }
        },
        hybridEnabled : (state) => state.hybridEnabled,
        hybridAddress : (state) => state.hybridAddress,
        hybridAPIURL : (state) => state.hybridAddress+':8010/pos',
        hybridPDFServerURL : (state) => state.hybridAddress+':8022',
        hybridSocketURL : (state) => state.hybridAddress+':8025',
        hybridCustomerDisplayURL : (state) => state.hybridAddress+':8035',
        pdfServer(state, getters){
            return ((process.env["VUE_APP_ELECTRON"] === "true" || process.env["VUE_APP_CAPACITOR"] === "true") && getters.hybridEnabled && getters.hybridAddress)
                ? getters.hybridPDFServerURL
                : process.env["VUE_APP_PDF_SERVER_HOST"];
        },
        cashierAliasName: (state, getters, rootState) => {
            let cashiers = rootState.cashierIDs.cashiers;
            let cashierID = state.cashierID;

            let cashier = cashiers.find((cashier) => cashier.id === cashierID);

            if (!cashier)
                return "";

            return cashier.aliasName;
        },
    }
};
