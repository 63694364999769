import randomString from "randomstring";
import Vue from "vue";
import { Events } from "@/plugins/events";
import { roundNumber } from "@/plugins/round";
import { calcGrossPrice, getNetPrice } from "@/plugins/tax";
import { format } from "mathjs";

export default {
  namespaced: true,

  state: {
    pagerNo: null,
    pickup: "",
    takeAway: 0,
    additionalStatisticUUIDs: [],
    lockedTables: [],
    isDeliveryService: false,
    deliveryInfo: null,
    serviceTime: null,
    lastRoom: {
      index: 0,
      id: 0,
    },
    deliveryPayment: 1,
    table: {
      name: 0,
      parties: [],
    },
    party: null,
    customer: null,
    customerProject: null,
    openItems: {},
    orderedItems: {},
    voidedItems: {},
    selectedCourse: null,
    selectedOpenItem: null,
    selectedOrderedItem: null,
    freeText: null,
    transactionPayload: {},
    is_reservation_order: false, //we can use this flag to avoid loading parties from order when we enter into gastro
    reservation: null, //here we can store reservation uuid to delete it when we submit reserved items
    delivery: false, //delivery status if on use delivery, else use pickup;
    isDirectSale: false,
    forcedVOR: false,
    orderUUIDS: {}, // storing order uuid of each party
    gastroOrder: null, // need a reference to the the order
  },

  mutations: {
    // THIS IS KINDA DANGEROUS MAKE SURE TO PASS A COPY BASED ON GASTRO STATE
    /* SPECIAL CASES:
            GASTRO MULTI USER
        */
    updateState(state, newState) {
      for (const key in newState) {
        if (newState.hasOwnProperty(key) && state.hasOwnProperty(key)) {
          state[key] = newState[key];
        }
      }
    },
    setGastroOrder(state, data) {
      if (
        data &&
        data.orders["Partei 1"] &&
        data.orders["Partei 1"].length < 1
      ) {
        state.gastroOrder = null;
        return;
      }
      state.gastroOrder = JSON.parse(JSON.stringify(data));
    },
    setLockedTables(state, data) {
      state.lockedTables = data;
    },
    setForcedVOR(state, data) {
      state.forcedVOR = data;
    },
    setTableParties(state, data) {
      state.table.parties = data;
    },
    enableDirectSaleMode(state) {
      state.isDirectSale = true;
    },
    disableDirectSaleMode(state) {
      state.isDirectSale = false;
    },
    setRoom(state, data) {
      Vue.set(state, "lastRoom", data);
    },
    setVoidedItems(state, data) {
      Vue.set(state.voidedItems, data.partyName, data.items);
    },
    setPickup(state, payload) {
      if (payload) state.pickup = payload.toString();
      else state.pickup = "";
    },
    setDeliveryPayment(state, payload) {
      state.deliveryPayment = payload;
    },
    setDeliveryInfo(state, payload) {
      state.deliveryInfo = payload;
    },
    setDelivery(state, payload) {
      state.delivery = payload;
      this.commit("pos/gastro/handleDeliveryCost");
      this.commit("pos/gastro/setItemsPrice");
    },
    setOrderAsReservation: (state, payload) => {
      state.is_reservation_order = payload;
    },
    setReservation: (state, payload) => {
      state.reservation = payload;
    },
    setDeliveryService(state, val) {
      state.isDeliveryService = val;
    },
    setServiceTime(state, val) {
      state.serviceTime = val;
    },
    setTakeAway(state, val) {
      state.takeAway = val;
    },
    setAdditionalStatistic(state, val) {
      state.additionalStatisticUUIDs = val;
    },
    setPagerNo(state, val) {
      state.pagerNo = val;
    },
    setFreetext(state, text) {
      state.freeText = text;
    },
    async bookItem(state, item, mobile = false) {
      //RESET SELECTED
      state.selectedOpenItem = null;
      state.selectedOrderedItem = null;

      //ITEMS
      if (
        state.party &&
        state.party.hasOwnProperty("name") &&
        !state.openItems.hasOwnProperty(state.party.name)
      ) {
        Vue.set(state.openItems, state.party.name, []);
      }

      let items = state.openItems[state.party.name];

      //CHECK IF ITEM ALREADY ORDERED
      const cartItem = items.find((cartItem) => {
        if (cartItem.id === item.id) {
          if (item.weight === null) {
            if (cartItem.freeText === null) {
              if (cartItem.originalSellPrice === item.originalSellPrice) {
                if (cartItem.sellPrice === item.sellPrice) {
                  if (cartItem.discount === item.discount) {
                    if (!cartItem.isVoid) {
                      if (
                        cartItem.selectedExtras.length === 0 &&
                        item.selectedExtras.length === 0
                      ) {
                        if (
                          cartItem.course === null ||
                          (item.course != null &&
                            cartItem.course.id === item.course.id)
                        ) {
                          if (cartItem.mealSizeID === item.mealSizeID) {
                            if (cartItem.batchNo === item.batchNo) {
                              if (
                                cartItem.bestBeforeDate === item.bestBeforeDate
                              ) {
                                if (cartItem.serialNo === item.serialNo) {
                                  if (
                                    cartItem.individualBookingNo ===
                                    item.individualBookingNo
                                  ) {
                                    if (
                                      (item.warenameOverwrite === null &&
                                        cartItem.name === item.name) ||
                                      (item.warenameOverwrite !== null &&
                                        cartItem.name ===
                                          item.warenameOverwrite)
                                    ) {
                                      return true;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        return false;
      });

      if (!cartItem) {
        let item2 = {
          amount: isNaN(parseFloat(item.weight)) ? 1.0 : item.weight,
          isWeighted: item.isWeighted,
          scalePositionId: item.scalePositionId,
          randomPositionID: randomString.generate(15),
          id: item.id,
          name:
            item.warenameOverwrite !== null &&
            item.warenameOverwrite !== undefined
              ? item.warenameOverwrite
              : item.name,
          ean: item.ean,
          course: item.course,
          icon: item.icon,
          badgeColor: item.badgeColor,
          sellPrice: item.sellPrice,
          // netPrice: item.sellPrice - ((item.sellPrice * item.taxValue) / 100),
          originalSellPrice: item.originalSellPrice,
          selectedExtras: item.selectedExtras,
          discount: item.discount,
          deliveryDiscount: item.deliveryDiscount,
          pickupDiscount: item.pickupDiscount,
          happyHourDiscount: item.happyHourDiscount,
          customerGroupDiscount: item.customerGroupDiscount,
          mealSizeID: item.mealSizeID,
          internalWareID: item.internalWareID,
          itemgroupID: item.itemgroupID,
          depositgroupID: item.depositgroupID,
          isPriceChanged: item.isPriceChanged,
          isVoid: false,
          needsVoidPrint: false,
          voidReason: "",
          freeText: item.freeText,
          taxValue: item.taxValue,
          takeAwayTaxing: item.takeAwayTaxing,
          isVariation: item.isVariation,
          variationColor: item.variationColor,
          variationSize: item.variationSize,
          bestBeforeDate: item.bestBeforeDate,
          batchNo: item.batchNo,
          serialNo: item.serialNo,
          isMenuItem: item.isMenuItem,
          menuTaxRules: item.menuTaxRules,
          payOutItem: item.payOutItem,
          additionalStatisticUUIDs: [],
          individualBookingNo: item.individualBookingNo,
          itemNotDiscountable: item.itemNotDiscountable === 1,
          priceCannotChange: item.priceCannotChange === 1,
        };

        if (item?.ean?.includes("GF$") && item?.min_redeem_cart_value) {
          item2.min_redeem_cart_value = item.min_redeem_cart_value;
        }

        items.unshift(item2);
        //Vue.set(items, 0, item2);

        //SELECT ITEM
        if (!item.isMobile) {
          this.commit("pos/gastro/selectInvoiceOpenItem", item2);
        }
      } else {
        if (Number(item.dailyAmount)) {
          if (cartItem.amount >= Number(item.dailyAmount)) {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_cantChooseMoreThan') + ' ' + Number(item.dailyAmount),
              color: "warning",
            });
            return;
          }
        }

        cartItem.amount++;

        //SELECT ITEM
        if (!item.isMobile) {
          this.commit("pos/gastro/selectInvoiceOpenItem", cartItem);
        }
      }
    },
    setOrderedItems(state, data) {
      Vue.set(
        state.orderedItems,
        data.partyName,
        data.orders.map((item) => ({
          ...item,
          ean: item.ean === null || item.ean === undefined ? "" : item.ean,
        }))
      );
    },
    setOrderUUIDS(state, data) {
      state.orderUUIDS = data;
    },
    setOpenItems(state, data) {
      Vue.set(
        state.openItems,
        data.party.name,
        data.orders.map((item) => ({
          ...item,
          ean: item.ean === null || item.ean === undefined ? "" : item.ean,
        }))
      );
    },
    appendOpenItems(state, data) {
      state.openItems[data.party.name].push(...data.orders);
    },
    posGastroSetTableBookedItems(state, data) {
      state.orderedItems = data.orders;
    },
    selectInvoiceOpenItem(state, item) {
      state.selectedBookedItem = null;
      state.selectedOrderedItem = null;

      if (state.selectedOpenItem !== item) {
        state.selectedOpenItem = item;
      } else {
        state.selectedOpenItem = null;
      }
    },
    selectInvoiceOrderedItem(state, item) {
      state.selectedOpenItem = null;

      if (state.selectedOrderedItem !== item) {
        state.selectedOrderedItem = item;
      } else {
        state.selectedOrderedItem = null;
      }
    },
    orderItems(state, data) {
      Vue.set(state.openItems, data.party.name, []);

      //SET ALSO NEED VOID PRINT STATE TO FALSE
      if (state.orderedItems.hasOwnProperty(data.party.name))
        state.orderedItems[data.party.name].forEach((orderedItem) => {
          orderedItem.needsVoidPrint = false;
        });
    },
    addTableParty(state, data) {
      if (
        data.party &&
        !Number.isNaN(parseInt(data.party.name)) &&
        parseInt(data.party.name) > 0
      ) {
        let party = { ...data.party };
        Object.assign(party, {
          name: "Partei " + data.party.name,
        });
        Vue.set(state.table.parties, state.table.parties.length, party);
      } else
        Vue.set(state.table.parties, state.table.parties.length, data.party);
    },
    deleteTableParty(state, party) {
      //DELETE PARTY ORDERED / OPEN ITEMS
      Vue.delete(state.openItems, party);
      Vue.delete(state.orderedItems, party);

      //DELETE PARTY
      //FIND PARTY INDEX
      let index = state.table.parties.findIndex((partyToFind) => {
        return partyToFind.name === party;
      });

      Vue.delete(state.table.parties, index);
    },
    clearTableParties(state) {
      if (state.table) state.table.parties = [];
    },
    setTable(state, data) {
      state.table = data.table;

      /*
            state.table.name = data.tableName;
            state.table.parties = data.parties;
            */

      if (state.table !== null) {
        if (Array.isArray(data.table.parties) && data.table.parties.length > 0)
          state.party = {
            name: data.table.parties[0].name,
            is_payed: Boolean(data.table.parties[0].is_payed),
          };

        if (state.table.name === data.table.name) {
          state.openItems = {};
          state.orderedItems = {};
        }
      }
    },
    changeTable(state, data) {
      state.table.name = data.name;
      state.table.uuid = data.uuid;
    },
    changeParty(state, data) {
      if (data.posType === "gastro") {
        state.party = data.party;

        //RESET SELECTED ITEMS
        state.selectedOpenItem = null;
        state.selectedBookedItem = null;
      }
    },
    setCustomer(state, data) {
      state.customer = data;
    },
    setCustomerProject(state, data) {
      state.customerProject = data;
    },
    setItemExtras(state, data) {
      state.openItems[state.party.name][data.index].selectedExtras =
        data.extras;
      state.openItems[state.party.name][data.index].sellPrice = data.newPrice;
    },
    numpadQTY(state, data) {
      if (state.selectedOpenItem) {
        if (
          state.selectedOpenItem &&
          !state.selectedOpenItem.isWeighted &&
          !state.selectedOpenItem.isVoid &&
          !state.selectedOpenItem.ean.includes("EVTB$") &&
          !state.selectedOpenItem.ean.includes("V$") &&
          !state.selectedOpenItem.ean.includes("GF$") &&
          !state.selectedOpenItem.ean.includes("ZMYLE$") &&
          !state.selectedOpenItem.ean.includes("MYWORLD$")
        ) {
          state.selectedOpenItem.amount =
            data > 0 ? Number(data.replace(",", ".")) : 0;
        }
      }

      if (state.selectedBookedItem) {
        if (
          state.selectedBookedItem &&
          !state.selectedBookedItem.isWeighted &&
          !state.selectedBookedItem.isVoid &&
          !state.selectedBookedItem.ean.includes("EVTB$") &&
          !state.selectedBookedItem.ean.includes("V$") &&
          !state.selectedBookedItem.ean.includes("GF$") &&
          !state.selectedBookedItem.ean.includes("ZMYLE$") &&
          !state.selectedBookedItem.ean.includes("MYWORLD$")
        ) {
          state.selectedBookedItem.amount =
            data > 0 ? Number(data.replace(",", ".")) : 0;
        }
      }
    },
    numpadPrice(state, data) {
      if (state.selectedOpenItem) {
        if (
          !state.selectedOpenItem.isVoid &&
          !state.selectedOpenItem.priceCannotChange &&
          !state.selectedOpenItem.ean.includes("V$") &&
          !state.selectedOpenItem.ean.includes("GF$") &&
          !state.selectedOpenItem.ean.includes("ZMYLE$") &&
          !state.selectedOpenItem.ean.includes("MYWORLD$")
        ) {
          //CHECK IF PAY OUT ITEM
          if (state.selectedOpenItem.payOutItem === 1)
            state.selectedOpenItem.sellPrice = parseFloat(data) * -1;
          else state.selectedOpenItem.sellPrice = parseFloat(data);

          state.selectedOpenItem.netPrice =
            state.selectedOpenItem.sellPrice -
            (state.selectedOpenItem.sellPrice *
              state.selectedOpenItem.taxValue) /
              100;

          if (
            state.selectedOpenItem.sellPrice !==
            state.selectedOpenItem.originalSellPrice
          ) {
            state.selectedOpenItem.isPriceChanged = true;
          } else {
            state.selectedOpenItem.isPriceChanged = false;
          }
        }
      }

      if (state.selectedBookedItem) {
        if (
          !state.selectedBookedItem.isVoid &&
          !state.selectedBookedItem.ean.includes("V$") &&
          !state.selectedBookedItem.ean.includes("GF$") &&
          !state.selectedBookedItem.ean.includes("ZMYLE$") &&
          !state.selectedBookedItem.ean.includes("MYWORLD$")
        ) {
          //CHECK IF PAY OUT ITEM
          if (state.selectedBookedItem.payOutItem === 1)
            state.selectedBookedItem.sellPrice = parseFloat(data) * -1;
          else state.selectedBookedItem.sellPrice = parseFloat(data);

          state.selectedBookedItem.isPriceChanged =
            state.selectedBookedItem.sellPrice !==
            state.selectedBookedItem.originalSellPrice;
        }
      }
    },
    setTransactionPayload(state, payload) {
      state.transactionPayload = payload;
    },

    handleDeliveryCost(state) {
      if (state.delivery) {
        // checking if we have already a delivery cost item in ordered items
        if (state.orderedItems) {
          for (const party in state.orderedItems) {
            let index = state.orderedItems[party].findIndex((item) =>
              item.randomPositionID.includes("$deliveryCost")
            );
            if (index > -1) {
              if (
                Number(state.orderedItems[party][index].sellPrice) !==
                Number(state.deliveryInfo.deliveryCost)
              ) {
                state.orderedItems[party].splice(index, 1);
              } else {
                return;
              }
            }
          }
        }

        // checking if we have already a delivery cost item in open items
        if (state.openItems.hasOwnProperty(state.party.name)) {
          for (let party in state.openItems) {
            let index = state.openItems[party].findIndex((item) =>
              item.randomPositionID.includes("$deliveryCost")
            );
            if (index > -1) {
              if (
                state.openItems[party][index].sellPrice !==
                state.deliveryInfo.deliveryCost
              ) {
                state.openItems[party].splice(index, 1);
              } else {
                return;
              }
            }
          }
          state.openItems[state.party.name].push({
            amount: 1,
            randomPositionID: "$deliveryCost_" + randomString.generate(15),
            id: 0,
            name: "Delivery Cost to " + state.deliveryInfo.zipCode,
            ean: "",
            course: null,
            icon: null,
            badgeColor: null,
            sellPrice: Number(state.deliveryInfo.deliveryCost),
            originalSellPrice: Number(state.deliveryInfo.deliveryCost),
            selectedExtras: [],
            discount: 0,
            mealSizeID: 0,
            internalWareID: 0,
            itemgroupID: 0,
            depositgroupID: null,
            isPriceChanged: false,
            isVoid: false,
            needsVoidPrint: false,
            voidReason: null,
            freeText: null,
            taxValue: Number(
              this.getters["settings/getSettingValue"](
                "lb_deliveryCostsTaxRate"
              )
            ),
            takeAwayTaxing: null,
            isVariation: null,
            variationColor: null,
            variationSize: null,
            bestBeforeDate: null,
            batchNo: null,
            serialNo: null,
            payOutItem: null,
            individualBookingNo: null,
          });
        } else {
          this.commit("pos/gastro/setOpenItems", {
            party: {
              name: state.party.name,
            },
            orders: [
              {
                amount: 1,
                randomPositionID: "$deliveryCost_" + randomString.generate(15),
                id: 0,
                name: "Delivery Cost to " + state.deliveryInfo.zipCode,
                ean: "",
                course: null,
                icon: null,
                badgeColor: null,
                sellPrice: Number(state.deliveryInfo.deliveryCost),
                originalSellPrice: Number(state.deliveryInfo.deliveryCost),
                selectedExtras: [],
                discount: 0,
                mealSizeID: 0,
                internalWareID: 0,
                itemgroupID: 0,
                depositgroupID: null,
                isPriceChanged: false,
                isVoid: false,
                needsVoidPrint: false,
                voidReason: null,
                freeText: null,
                taxValue: Number(
                  this.getters["settings/getSettingValue"](
                    "lb_deliveryCostsTaxRate"
                  )
                ),
                takeAwayTaxing: null,
                isVariation: null,
                variationColor: null,
                variationSize: null,
                bestBeforeDate: null,
                batchNo: null,
                serialNo: null,
                payOutItem: null,
                individualBookingNo: null,
              },
            ],
          });
        }
      } else {
        if (state.orderedItems) {
          for (const party in state.orderedItems) {
            let index = state.orderedItems[party].findIndex((item) =>
              item.randomPositionID.includes("$deliveryCost")
            );
            if (index > -1) {
              state.orderedItems[party].splice(index, 1);
            }
          }
        }

        if (state.openItems) {
          for (const party in state.openItems) {
            let index = state.openItems[party].findIndex((item) =>
              item.randomPositionID.includes("$deliveryCost")
            );
            if (index > -1) {
              state.openItems[party].splice(index, 1);
            }
          }
        }
      }
    },
    setItemsPrice(state) {
      let baseItem = null;
      let group = null;

      if (!state.openItems[state.party.name]) return;

      state.openItems[state.party.name].forEach(async (item) => {
        if (!item.randomPositionID.includes("$deliveryCost")) {
          let discount = 0;
          baseItem = await this.dispatch("items/getItemByID", item.id);
          if (baseItem) {
            if (!item.mealSizeID)
              item.sellPrice = state.delivery
                ? baseItem.deliveryPrice > 0
                  ? baseItem.deliveryPrice
                  : item.sellPrice
                : baseItem.takeAwayPrice > 0
                ? baseItem.takeAwayPrice
                : item.sellPrice;
          }

          group = await this.dispatch(
            "itemgroups/getItemgroupByID",
            item.itemgroupID
          );
          if (group) {
            if (state.delivery) {
              discount = Number(group.deliveryDiscount);
            } else {
              discount = Number(group.takeAwayDiscount);
            }
          }

          if (
            state.customer &&
            state.customer.hasOwnProperty("itemgroupDiscount")
          ) {
            if (state.customer.itemgroupDiscount !== null) {
              if (Object.keys(state.customer.itemgroupDiscount).length > 0) {
                if (
                  state.customer.itemgroupDiscount.hasOwnProperty(
                    item.itemgroupID
                  )
                ) {
                  if (
                    state.customer.itemgroupDiscount[item.itemgroupID] >
                    discount
                  ) {
                    discount =
                      state.customer.itemgroupDiscount[item.itemgroupID];
                  }
                }
              }
            }
          }

          item.discount = discount;
        }
      });
    },
  },

  actions: {
    setTakeAway({ commit }, data) {
      commit("setTakeAway", data);
    },
    updateGastroState({ commit }, data) {
      commit("updatePosState", data);
    },
    setGastroOrder({ commit }, data) {
      commit("setGastroOrder", data);
    },
    setLockedTables({ commit }, data) {
      commit("setLockedTables", data);
    },
    setForcedVOR({ commit }, data) {
      commit("setForcedVOR", data);
    },
    setTableParties({ commit }, data) {
      commit("setTableParties", data);
    },
    setServiceTime({ commit }, data) {
      commit("setServiceTime", data);
    },
    setPickup({ commit }, data) {
      commit("setPickup", data);
    },
    setDelivery({ commit }, data) {
      commit("setDelivery", data);
    },
    setPagerNo({ commit }, data) {
      commit("setPagerNo", data);
    },
    setDeliveryService({ commit }, data) {
      commit("setDeliveryService", data);
    },
    bookItem({ commit }, data) {
      commit("bookItem", data);
    },
    setOrderedItems({ commit }, data) {
      commit("setOrderedItems", data);
    },
    setOrderUUIDS({ commit }, data) {
      commit("setOrderUUIDS", data);
    },
    setOpenItems({ commit }, data) {
      commit("setOpenItems", data);
    },
    appendOpenItems({ commit }, data) {
      commit("appendOpenItems", data);
    },
    selectInvoiceOpenItem({ commit }, data) {
      commit("selectInvoiceOpenItem", data);
    },
    selectInvoiceOrderedItem({ commit }, data) {
      commit("selectInvoiceOrderedItem", data);
    },
    orderItems({ commit }, data) {
      commit("orderItems", data);
    },
    addTableParty({ state, commit }, data) {
      return new Promise((resolve) => {
        commit("addTableParty", data);
        //get last party
        if (
          Array.isArray(state.table.parties) &&
          state.table.parties.length > 0
        ) {
          resolve(state.table.parties[state.table.parties.length - 1].name);
        } else resolve();
      });
    },
    setTable({ commit }, data) {
      commit("setTable", data);
    },
    changeTable({ commit }, data) {
      commit("changeTable", data);
    },
    changeParty({ commit }, data) {
      commit("changeParty", data);
    },
    setCustomer({ commit }, data) {
      commit("setCustomer", data);
    },
    setCustomerProject({ commit }, data) {
      commit("setCustomerProject", data);
    },
    setItemExtras({ commit }, data) {
      commit("setItemExtras", data);
    },
    numpadQTY({ commit }, data) {
      commit("numpadQTY", data);
    },
    numpadPrice({ commit }, data) {
      commit("numpadPrice", data);
    },
    setTransactionPayload({ commit }, data) {
      commit("setTransactionPayload", data);
    },
  },

  getters: {
    canOrder: (state) => {
      let customerChanged = false;
      let orderChanged = false;

      if (state.party) {
        if (state.orderedItems.hasOwnProperty(state.party.name)) {
          if (state.orderedItems[state.party.name]) {
            if (state.gastroOrder && state.gastroOrder.orders) {
              orderChanged =
                JSON.stringify(state.orderedItems[state.party.name]) !==
                JSON.stringify(state.gastroOrder.orders[state.party.name]);
            }
          }
        }
      }
      customerChanged =
        (state.gastroOrder ? state.gastroOrder.customerId : 0) !==
        (state.customer ? state.customer.id : 0);
      return (
        (customerChanged && !(state.table && Number(state.table.name) === 0)) ||
        orderChanged
      );
    },
    canOrderParty: (state) => (partyName) => {
      let customerChanged =
        (state.gastroOrder ? state.gastroOrder.customerId : 0) !==
        (state.customer ? state.customer.id : 0);
      return (
        customerChanged ||
        (state.openItems[partyName] && state.openItems[partyName].length > 0) ||
        (state.orderedItems[partyName] &&
          state.gastroOrder.orders &&
          JSON.stringify(state.orderedItems[partyName]) !==
            JSON.stringify(state.gastroOrder.orders[partyName]))
      );
    },
    lockedTables: (state) => state.lockedTables,
    gastroOrder: (state) => state.gastroOrder,
    customer: (state) => state.customer,
    isDeliveryService: (state) => state.isDeliveryService,
    forcedVOR: (state) => state.forcedVOR,
    currentParty: (state) => {
      return state.party;
    },
    is_directSale: (state) => state.isDirectSale,
    getDeliveryPayment: (state) => {
      return state.deliveryPayment;
    },
    getDeliveryInfo: (state) => {
      return state.deliveryInfo;
    },
    getServiceTime: (state) => {
      return state.serviceTime;
    },
    getDelivery: (state) => {
      return state.delivery;
    },
    isFromReservation: (state) => state.is_reservation_order,
    reservationUUID: (state) => state.reservation,
    selectedOrderedItem: (state) => {
      return state.selectedOrderedItem;
    },
    selectedOpenItem: (state) => {
      return state.selectedOpenItem;
    },
    getGastroPosTypeOfSelectedItem: (state) => {
      if (state.selectedOpenItem != null) {
        return "open";
      } else if (state.selectedBookedItem != null) {
        return "booked";
      } else {
        return null;
      }
    },
    tableName: (state) => {
      if (state.table === null) {
        return null;
      }

      if (!state.table.hasOwnProperty("name")) {
        return null;
      }

      return state.table.name;
    },
    openItems: (state) => {
      if (state.party === null) {
        return [];
      }

      if (state.party && !state.party.hasOwnProperty("name")) {
        return [];
      }

      if (
        state.openItems &&
        state.party &&
        state.party.hasOwnProperty("name") &&
        !state.openItems.hasOwnProperty(state.party.name)
      ) {
        return [];
      }

      if (state.party && state.party.hasOwnProperty("name")) {
        return state.openItems[state.party.name];
      } else return [];
    },
    countOpenItems: (state) => {
      let counter = 0;
      if (state.party === null) {
        return counter;
      }

      if (!state.party.hasOwnProperty("name")) {
        return counter;
      }

      if (
        state.party &&
        state.party.hasOwnProperty("name") &&
        !state.openItems.hasOwnProperty(state.party.name)
      ) {
        return counter;
      }
      Array.from(state.openItems[state.party.name]).forEach(function (
        openElements
      ) {
        counter += openElements.amount;
      });
      return counter;
    },
    orderedItems: (state) => {
      if (state.party === null) {
        return [];
      }

      if (!state.party.hasOwnProperty("name")) {
        return [];
      }

      if (!state.orderedItems.hasOwnProperty(state.party.name)) {
        return [];
      }
      return state.orderedItems[state.party.name];
    },
    countOrderedItems: (state) => {
      let counter = 0;
      if (state.party === null) {
        return counter;
      }

      if (!state.party.hasOwnProperty("name")) {
        return counter;
      }

      if (
        state.party &&
        state.party.hasOwnProperty("name") &&
        !state.openItems.hasOwnProperty(state.party.name)
      ) {
        return counter;
      }
      Array.from(state.orderedItems[state.party.name] || []).forEach(function (
        orderedElements
      ) {
        counter += orderedElements.amount;
      });
      return counter;
    },
    openItemsTotalPrice(state, getters, rootState) {
      let total = 0;

      //TOTAL 1
      for (let party in state.openItems) {
        if (!state.openItems.hasOwnProperty(party)) {
          continue;
        }

        party = state.openItems[party];

        party.forEach((item) => {
          if (!item.isVoid && item.amount > 0) {
            //CHECK IF DEPOSIT
            if (item.hasOwnProperty("depositgroupID")) {
              if (item.depositgroupID > 0) {
                let deposit = rootState.deposit.deposits.find(
                  (depositGroup) => {
                    return depositGroup.id === item.depositgroupID;
                  }
                );

                if (deposit)
                  total += item.amount * parseFloat(deposit.priceTotal);
              }
            }

            let discount = 0;

            if (item.hasOwnProperty("discount") && item.discount)
              discount = item.discount;

            //total += parseFloat((item.amount * parseFloat((((100 - discount) / 100) * item.sellPrice).toFixed(2))).toFixed(2));
            //total += parseFloat(((item.amount * parseFloat(Math.round(((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100))).toFixed(2));
            let tempPrice = parseFloat(
              ((item.sellPrice * ((100 - discount) / 100) + Number.EPSILON) *
                100) /
                100
            );
            tempPrice = format(tempPrice, { precision: 14 });
            tempPrice = item.amount * roundNumber(tempPrice, 2);

            total += parseFloat(tempPrice);
          }
        });
      }

      let newEndTotal = format(total, { precision: 14 });
      newEndTotal = roundNumber(newEndTotal, 2);

      return newEndTotal;
    },
    totalPrice: (state, getters, rootState) => {
      let total1 = 0;
      let total2 = 0;

      //TOTAL 1
      for (let party in state.openItems) {
        if (!state.openItems.hasOwnProperty(party)) {
          continue;
        }

        party = state.openItems[party];

        party.forEach((item) => {
          if (!item.isVoid && item.amount > 0) {
            //CHECK IF DEPOSIT
            if (item.hasOwnProperty("depositgroupID")) {
              if (item.depositgroupID > 0) {
                let deposit = rootState.deposit.deposits.find(
                  (depositGroup) => {
                    return depositGroup.id === item.depositgroupID;
                  }
                );

                if (deposit)
                  total1 += item.amount * parseFloat(deposit.priceTotal);
              }
            }

            let discount = 0;

            if (item.hasOwnProperty("discount") && item.discount)
              discount = item.discount;

            //total1 += parseFloat((item.amount * parseFloat((((100 - discount) / 100) * item.sellPrice).toFixed(2))).toFixed(2));
            //total1 += parseFloat(((item.amount * parseFloat(Math.round(((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100))).toFixed(2));
            let tempPrice = parseFloat(
              ((item.sellPrice * ((100 - discount) / 100) + Number.EPSILON) *
                100) /
                100
            );
            tempPrice = format(tempPrice, { precision: 14 });
            tempPrice = item.amount * roundNumber(tempPrice, 2);

            total1 += parseFloat(tempPrice);
          }
        });
      }

      //TOTAL 2
      for (let party in state.orderedItems) {
        if (!state.orderedItems.hasOwnProperty(party)) {
          continue;
        }

        party = state.orderedItems[party];

        party.forEach((item) => {
          if (!item.isVoid && item.amount > 0) {
            //CHECK IF DEPOSIT
            if (item.hasOwnProperty("depositgroupID")) {
              if (item.depositgroupID > 0) {
                let deposit = rootState.deposit.deposits.find(
                  (depositGroup) => {
                    return depositGroup.id === item.depositgroupID;
                  }
                );

                if (deposit) total2 += item.amount * deposit.priceTotal;
              }
            }

            let discount = 0;

            if (item.hasOwnProperty("discount") && item.discount) {
              discount = item.discount;
            }

            //total2 += parseFloat((item.amount * parseFloat((((100 - discount) / 100) * item.sellPrice).toFixed(2))).toFixed(2));
            //total2 += parseFloat(((item.amount * parseFloat(Math.round(((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100))).toFixed(2));
            //total2 +=  parseFloat( item.amount * parseFloat(roundNumber(((((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100),2)));

            let tempPrice = parseFloat(
              ((item.sellPrice * ((100 - discount) / 100) + Number.EPSILON) *
                100) /
                100
            );
            tempPrice = format(tempPrice, { precision: 14 });
            tempPrice = item.amount * roundNumber(tempPrice, 2);
            total2 += parseFloat(tempPrice);
          }
        });
      }

      let newEndTotal = format(total1 + total2, { precision: 14 });
      newEndTotal = roundNumber(newEndTotal, 2);

      return newEndTotal;
    },
    // JUST CALCULATING THE TOTAL WITHOUT THE VOUCHERS AND GIFT CARDS ETC
    totalWithoutGFV: (state, getters, rootState) => {
      let total1 = 0;
      let total2 = 0;

      //TOTAL 1
      for (let party in state.openItems) {
        if (!state.openItems.hasOwnProperty(party)) {
          continue;
        }

        party = state.openItems[party];

        party.forEach((item) => {
          if (
            item.sellPrice < 0 &&
            (item.ean.includes("V$") ||
              item.ean.includes("GF$") ||
              item.ean.includes("ZMYLE$") ||
              item.ean.includes("MYWORLD$"))
          ) {
            return;
          }
          if (!item.isVoid && item.amount > 0) {
            //CHECK IF DEPOSIT
            if (item.hasOwnProperty("depositgroupID")) {
              if (item.depositgroupID > 0) {
                let deposit = rootState.deposit.deposits.find(
                  (depositGroup) => {
                    return depositGroup.id === item.depositgroupID;
                  }
                );

                if (deposit)
                  total1 += item.amount * parseFloat(deposit.priceTotal);
              }
            }

            let discount = 0;

            if (item.hasOwnProperty("discount") && item.discount)
              discount = item.discount;

            //total1 += parseFloat((item.amount * parseFloat((((100 - discount) / 100) * item.sellPrice).toFixed(2))).toFixed(2));
            //total1 += parseFloat(((item.amount * parseFloat(Math.round(((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100))).toFixed(2));
            let tempPrice = parseFloat(
              ((item.sellPrice * ((100 - discount) / 100) + Number.EPSILON) *
                100) /
                100
            );
            tempPrice = format(tempPrice, { precision: 14 });
            tempPrice = item.amount * roundNumber(tempPrice, 2);

            total1 += parseFloat(tempPrice);
          }
        });
      }

      //TOTAL 2
      for (let party in state.orderedItems) {
        if (!state.orderedItems.hasOwnProperty(party)) {
          continue;
        }

        party = state.orderedItems[party];

        party.forEach((item) => {
          if (
            item.sellPrice < 0 &&
            (item.ean.includes("V$") ||
              item.ean.includes("GF$") ||
              item.ean.includes("ZMYLE$") ||
              item.ean.includes("MYWORLD$"))
          ) {
            return;
          }
          if (!item.isVoid && item.amount > 0) {
            //CHECK IF DEPOSIT
            if (item.hasOwnProperty("depositgroupID")) {
              if (item.depositgroupID > 0) {
                let deposit = rootState.deposit.deposits.find(
                  (depositGroup) => {
                    return depositGroup.id === item.depositgroupID;
                  }
                );

                if (deposit) total2 += item.amount * deposit.priceTotal;
              }
            }

            let discount = 0;

            if (item.hasOwnProperty("discount") && item.discount) {
              discount = item.discount;
            }

            //total2 += parseFloat((item.amount * parseFloat((((100 - discount) / 100) * item.sellPrice).toFixed(2))).toFixed(2));
            //total2 += parseFloat(((item.amount * parseFloat(Math.round(((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100))).toFixed(2));
            //total2 +=  parseFloat( item.amount * parseFloat(roundNumber(((((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100),2)));

            let tempPrice = parseFloat(
              ((item.sellPrice * ((100 - discount) / 100) + Number.EPSILON) *
                100) /
                100
            );
            tempPrice = format(tempPrice, { precision: 14 });
            tempPrice = item.amount * roundNumber(tempPrice, 2);
            total2 += parseFloat(tempPrice);
          }
        });
      }

      let newEndTotal = format(total1 + total2, { precision: 14 });
      newEndTotal = roundNumber(newEndTotal, 2);

      return newEndTotal;
    },
    subTotalPrice: (state, getters, rootState) => {
      let total1 = 0;
      let total2 = 0;

      //TOTAL 1
      for (let party in state.openItems) {
        if (!state.openItems.hasOwnProperty(party)) {
          continue;
        }

        party = state.openItems[party];

        party.forEach((item) => {
          if (!item.isVoid && item.amount > 0) {
            //CHECK IF DEPOSIT
            if (item.hasOwnProperty("depositgroupID")) {
              if (item.depositgroupID > 0) {
                let deposit = rootState.deposit.deposits.find(
                  (depositGroup) => {
                    return depositGroup.id === item.depositgroupID;
                  }
                );

                if (deposit)
                  total1 += item.amount * parseFloat(deposit.priceTotal);
              }
            }

            let discount = 0;

            if (item.hasOwnProperty("discount") && item.discount)
              discount = item.discount;

            //total1 += parseFloat((item.amount * parseFloat((((100 - discount) / 100) * item.sellPrice).toFixed(2))).toFixed(2));
            //total1 += parseFloat(((item.amount * parseFloat(Math.round(((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100))).toFixed(2));

            if (
              item.sellPrice < 0 &&
              (item.ean.includes("V$") || item.ean.includes("GF$"))
            ) {
              item.netPrice = item.sellPrice;
            } else {
              item.netPrice = getNetPrice(item.sellPrice, item.taxValue);
            }

            let tempPrice =
              ((item.netPrice * ((100 - discount) / 100) + Number.EPSILON) *
                100) /
              100;
            tempPrice = item.amount * tempPrice;

            total1 += tempPrice;
          }
        });
      }

      //TOTAL 2
      for (let party in state.orderedItems) {
        if (!state.orderedItems.hasOwnProperty(party)) {
          continue;
        }

        party = state.orderedItems[party];

        party.forEach((item) => {
          if (!item.isVoid && item.amount > 0) {
            //CHECK IF DEPOSIT
            if (item.hasOwnProperty("depositgroupID")) {
              if (item.depositgroupID > 0) {
                let deposit = rootState.deposit.deposits.find(
                  (depositGroup) => {
                    return depositGroup.id === item.depositgroupID;
                  }
                );

                if (deposit) total2 += item.amount * deposit.priceTotal;
              }
            }

            let discount = 0;

            if (item.hasOwnProperty("discount") && item.discount) {
              discount = item.discount;
            }

            //total2 += parseFloat((item.amount * parseFloat((((100 - discount) / 100) * item.sellPrice).toFixed(2))).toFixed(2));
            //total2 += parseFloat(((item.amount * parseFloat(Math.round(((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100))).toFixed(2));
            //total2 +=  parseFloat( item.amount * parseFloat(roundNumber(((((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100),2)));

            if (
              item.sellPrice < 0 &&
              (item.ean.includes("V$") || item.ean.includes("GF$"))
            ) {
              item.netPrice = item.sellPrice;
            } else {
              item.netPrice = getNetPrice(item.sellPrice, item.taxValue);
            }

            let tempPrice =
              ((item.netPrice * ((100 - discount) / 100) + Number.EPSILON) *
                100) /
              100;
            tempPrice = item.amount * tempPrice;
            total2 += tempPrice;
          }
        });
      }

      return roundNumber(total1 + total2, 2);
    },
    goodsValue: (state, getters, rootState) => {
      let total = 0;
      let items = [];

      if (getters["tableName"] === 0) items = state.openItems;
      else items = state.orderedItems;

      for (let party in items) {
        if (!items.hasOwnProperty(party)) {
          continue;
        }

        party = items[party];

        party.forEach((item) => {
          let itemPrice = 0;

          //CHECK IF VOIDED
          if (item.isVoid || item.amount === 0) {
            return;
          }

          if (
            item.sellPrice < 0 &&
            (item.ean.includes("V$") ||
              item.ean.includes("GF$") ||
              item.ean.includes("ZMYLE$") ||
              item.ean.includes("MYWORLD$"))
          ) {
            return;
          }

          //CHECK DISCOUNT
          let discount = 0;
          if (item.hasOwnProperty("discount") && !isNaN(item.discount)) {
            discount = item.discount;
          }

          //itemPrice = parseFloat((Math.round(((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100).toFixed(2));
          itemPrice = parseFloat(
            ((item.sellPrice * ((100 - discount) / 100) + Number.EPSILON) *
              100) /
              100
          );
          itemPrice = format(itemPrice, { precision: 14 });
          itemPrice = roundNumber(itemPrice, 2);

          //CHECK IF DEPOSIT
          if (item.hasOwnProperty("depositgroupID")) {
            if (item.depositgroupID > 0) {
              let deposit = rootState.deposit.deposits.find((depositGroup) => {
                return depositGroup.id === item.depositgroupID;
              });

              if (deposit) itemPrice += parseFloat(deposit.priceTotal);
            }
          }

          let weightCalc = 0.0;
          weightCalc = parseFloat(itemPrice * item.amount);
          weightCalc = format(weightCalc, { precision: 14 });
          weightCalc = roundNumber(weightCalc, 2);

          total += parseFloat(weightCalc.toFixed(2));
        });
      }

      return parseFloat(total.toFixed(2));
    },
  },
};
